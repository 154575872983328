import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { userService } from '@/services/userService'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FC, useCallback, useEffect, useState } from 'react'
import AmeModal from '../AmeModal/AmeModal'
import styles from './LoginModal.module.less'
import loginBg from '@/assets/login-modal/bg__login.png'
import IconBulb from '@/assets/login-modal/icon__bulb.png'
import Icon2 from '@/assets/pay-dialog/icon__desc-2.png'
import Icon3 from '@/assets/pay-dialog/icon__desc-3.png'
import Icon4 from '@/assets/pay-dialog/icon__desc-4.png'
import AmeIcon from '../AmeIcon/AmeIcon'
import LoadingImg from '@/assets/loading.gif'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import CheckNormalIcon from '@/assets/login-modal/check-normal.png'
import CheckActiveIcon from '@/assets/login-modal/check-active.png'
import CheckHoverIcon from '@/assets/login-modal/check-hover.png'

interface LoginModalProps {
  open: boolean
}

const DESC_LIST: Array<{ title: string; desc?: string; icon: string }> = [
  { title: '长文写作', desc: '满足您所有的写作需求', icon: Icon2 },
  { title: '30+AI模版', desc: '创意无限，AI模版多样', icon: Icon3 },
  { title: 'AI自定义命令', desc: '将创作流程化繁为简', icon: Icon4 },
]

const MAX_ERROR_COUNT = 3
let checkErrorCount = 0
let checkLoginStatusTimeout: NodeJS.Timeout | null = null
const getLoginParams = () => {
  try {
    const utm = storageHelper.get(['utm']).utm || ''
    const referer = storageHelper.get(['referer']).referer || ''
    const kword = storageHelper.get(['kword']).kword || ''
    const inviteCode = storageHelper.get(['inviteCode']).inviteCode || ''
    const bdVid = storageHelper.get(['bdVid']).bdVid || ''
    const bdUrl = storageHelper.get(['bdUrl']).bdUrl || ''
    let refererChannel = ''
    if (referer) {
      refererChannel = encodeURIComponent(new URL(referer).hostname)
    }
    return {
      utm,
      kword,
      refererChannel,
      refererChannelDetail: encodeURIComponent(referer),
      /** 增加浏览器类型 */
      browserType: commonUtils.getBrowserInfo().browser,
      /** 增加百度ocpc投放参数 */
      bdVid,
      bdUrl: encodeURIComponent(bdUrl),
      ...(inviteCode ? { inviteCode } : {}),
    }
  } catch (error) {
    return {
      utm: '',
      kword: '',
      refererChannel: '',
      refererChannelDetail: '',
    }
  }
}

const LoginModal: FC<LoginModalProps> = observer(
  ({ open }: LoginModalProps) => {
    const [loading, setLoading] = useState(false)
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null)
    const [privacyReadChecked, setPrivacyReadChecked] = useState<boolean>(false)
    const getUserLoginQrCode = async () => {
      try {
        setLoading(true)
        const param = getLoginParams()
        const {
          data: { url, sceneId },
        } = await api.user.getLoginQrCode(param)
        setLoading(false)
        return { qrCode: url, sceneId }
      } catch (error) {
        setLoading(false)
        console.error('getUserLoginQrCode error', error)
        return Promise.reject(error)
      }
    }

    const checkLoginStatus = useCallback(async (sceneId: string) => {
      try {
        const {
          data: { status },
        } = await api.user.checkLoginStatus(sceneId)
        if (status === 'no_scan') {
          clearTimeout(checkLoginStatusTimeout!)
          checkLoginStatusTimeout = setTimeout(() => {
            checkLoginStatus(sceneId)
          }, 1 * 1000)
        } else {
          uiController.message.success('登录成功')
          userService.updateUserInfo()
          uiController.showLoginModal = false
        }
      } catch (error) {
        console.error('check login error', error)
        checkErrorCount += 1
        if (checkErrorCount < MAX_ERROR_COUNT) {
          clearTimeout(checkLoginStatusTimeout!)
          checkLoginStatusTimeout = setTimeout(() => {
            checkLoginStatus(sceneId)
          }, 1 * 1000)
        } else {
          uiController.message.error('登录状态检测失败，请稍后重试或联系客服')
        }
      }
    }, [])

    useEffect(() => {
      if (!open) {
        return
      }
      let isUnmounted = false
      getUserLoginQrCode()
        .then(({ qrCode, sceneId }) => {
          if (isUnmounted) {
            return
          }
          setQrCodeUrl(qrCode)
          checkLoginStatus(sceneId)
        })
        .catch(() => {
          uiController.message.error('登录二维码获取失败，请稍后重试或联系客服')
        })
      return () => {
        isUnmounted = true
        clearTimeout(checkLoginStatusTimeout!)
      }
    }, [checkLoginStatus, open])
    return (
      <AmeModal
        footer={false}
        width="798px"
        className={styles.loginModal}
        open={open}
        onCancel={() => {
          clearTimeout(checkLoginStatusTimeout!)
          uiController.showLoginModal = false
        }}
      >
        <div className={classNames(styles.loginContent)}>
          <div className={classNames(styles.left)}>
            <img src={loginBg} alt="" className={classNames(styles.bg)} />
            <div>
              <p className={classNames(styles.title)}>
                <img src={IconBulb} alt="" />
                今天你想写点什么？
              </p>
              <p className={classNames(styles.desc)}>
                使用灵感快写，轻松写出高质量原创内容
              </p>
              {DESC_LIST.map((item, i) => {
                return (
                  <div key={i} className={classNames(styles.item)}>
                    <p>
                      <img src={item.icon} alt="" /> {item.title}
                    </p>
                    {item.desc && (
                      <p className={classNames(styles.desc)}>{item.desc}</p>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className={classNames(styles.right)}>
            <p className={classNames(styles.title)}>
              <AmeIcon
                type="icon-fill-global_wechat"
                style={{
                  fontSize: '24px',
                  marginRight: '6px',
                  color: '#09BB07',
                }}
              ></AmeIcon>
              请使用微信扫码
            </p>
            <div className={classNames(styles.qrCodeBox)}>
              {loading ? (
                <img
                  className={classNames(styles.qrCodeLoading)}
                  src={LoadingImg}
                  alt=""
                />
              ) : (
                qrCodeUrl && (
                  <img
                    key="qrCode"
                    className={classNames(styles.qrCode)}
                    src={qrCodeUrl}
                  />
                )
              )}
              {!privacyReadChecked && (
                <>
                  <div className={styles.maskBox}>
                    请先阅读并同意
                    <br />
                    服务协议
                  </div>
                </>
              )}
            </div>
            <p className={classNames(styles.desc)}>
              微信扫码关注「灵感快写」公众号，即可登录，无需注册
            </p>
            <div className={classNames(styles.protocolBox)}>
              <p
                className={classNames(
                  styles.hint,
                  styles.tips,
                  styles.checkHint
                )}
              >
                <span
                  className={classNames(styles.checkboxBtn, {
                    [styles.checkboxActive]: privacyReadChecked,
                  })}
                  onClick={() => {
                    setPrivacyReadChecked(!privacyReadChecked)
                    storageHelper.set({
                      privacyCheckedFlag: !privacyReadChecked,
                    })
                  }}
                >
                  <img className={styles.checkNormal} src={CheckNormalIcon} />
                  <img className={styles.checkHover} src={CheckHoverIcon} />
                  <img className={styles.checkActive} src={CheckActiveIcon} />
                </span>
                已阅读并同意
                <a
                  href="https://assets.weibanzhushou.com/web/pt-web/%E5%BF%AB%E5%86%99-%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.51441e87e73c.pdf"
                  target="_blank"
                >
                  《用户协议》
                </a>
                和
                <a
                  href="https://mpa.oss-cn-hangzhou.aliyuncs.com/web/kx-web/%E5%BF%AB%E5%86%99-%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E6%94%BF%E7%AD%9620240911.pdf"
                  target="_blank"
                >
                  《隐私政策》
                </a>
              </p>
            </div>
          </div>
        </div>
      </AmeModal>
    )
  }
)

export default LoginModal
